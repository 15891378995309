import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../layout/index'),
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('../views/dashboard/index'),
        meta: {
          showFooter: false,
          title: '首页',
          icon: 'el-icon-s-home'
        }
      }
    ]
  },
  {
    path: '/about',
    redirect: '/about/us',
    name: 'AboutUs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../layout/index'),
    children: [
      {
        path: 'us',
        name: 'About',
        component: () => import('../views/about/index'),
        meta: {
          showFooter: true,
          title: '关于我们',
          icon: 'el-icon-s-home'
        }
      }, {
        path: 'address',
        name: 'Address',
        component: () => import('../views/address/index'),
        meta: {
          showFooter: true,
          title: '联系我们',
          icon: 'el-icon-s-home'
        }
      }
    ]
  },
  {
    path: '/product',
    redirect: '/product/iot',
    name: 'Product',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../layout/index'),
    children: [
      {
        path: 'iot',
        name: 'SourceManage',
        component: () => import('../views/products/iot/index'),
        meta: {
          showFooter: true,
          title: '资产管控',
          icon: 'el-icon-s-home'
        }
      },
      {
        path: 'fy',
        name: 'AnonymousLine',
        component: () => import('../views/products/fy/index'),
        meta: {
          showFooter: true,
          title: '匿名链路',
          icon: 'el-icon-s-home'
        }
      },
      {
        path: 'source',
        name: 'Source',
        component: () => import('../views/products/source/index'),
        meta: {
          showFooter: true,
          title: '资源探测',
          icon: 'el-icon-s-home'
        }
      },
      {
        path: 'tool',
        name: 'Tool',
        component: () => import('../views/products/tool/index'),
        meta: {
          showFooter: true,
          title: '检测工具',
          icon: 'el-icon-s-home'
        }
      },
      {
        path: 'verify',
        name: 'Verify',
        component: () => import('../views/products/verify/index'),
        meta: {
          showFooter: true,
          title: '远程取证',
          icon: 'el-icon-s-home'
        }
      },
      {
        path: 'custom',
        name: 'Custom',
        component: () => import('../views/products/custom/index'),
        meta: {
          showFooter: true,
          title: '定制开发',
          icon: 'el-icon-s-home'
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
