<template>
  <div class="footer-main">
    <el-row type="flex" class="row-bg" justify="center">
      <el-col :xs="{ span: 24, offset: 0 }" :lg="{ span: 6, offset: 0 }">
        <div>
          <div>
            <div class="footer-logo"></div>
            <div class="footer-logo-text">SHU DAO XIN AN</div>
            <div class="footer-logo-desc">—— technology ——</div>
          </div>
        </div>
      </el-col>
      <el-col :xs="{ span: 24, offset: 0 }" :lg="{ span: 6, offset: 0 }"
        ><div class="footer-mian-prd">
          <div class="footer-mian-prd-title">产品服务</div>
          <div
            class="footer-mian-prd-content"
            v-for="item in services"
            @click="Jump(item)"
          >
            {{ item.name }}
          </div>
        </div></el-col
      >
      <el-col :xs="{ span: 24, offset: 0 }" :lg="{ span: 6, offset: 0 }"
        ><div class="footer-mian-prd">
          <div class="footer-mian-prd-title">关于我们</div>
          <div
            class="footer-mian-prd-content"
            v-for="i in abouts"
            @click="Jump(i)"
          >
            {{ i.name }}
          </div>
        </div></el-col
      >
    </el-row>
    <el-row type="flex" class="row-bg" justify="center">
      <el-col :xs="{ span: 24, offset: 0 }" :lg="{ span: 18, offset: 0 }"
        ><div class="footer-border"></div
      ></el-col>
    </el-row>
    <el-row type="flex" class="row-bg" justify="center">
      <el-col :xs="{ span: 24, offset: 0 }" :lg="{ span: 18, offset: 0 }"
        ><div class="footer-mian-prd-content">
          Copyright © 2020-2024 shudaoxinan.com | 蜀道信安
        </div></el-col
      >
    </el-row>

    <div v-if="showButton" @click="scrollToTop" class="back-to-top">
      <div><i class="el-icon-top"></i></div>
    </div>
    <div></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showButton: false,
      abouts: [
        {
          name: '公司介绍',
          path: 'About'
        },
        {
          name: '联系我们',
          path: 'Address'
        }
      ],
      services: [
        {
          name: '大规模IOT设备智能管控平台',
          path: 'SourceManage'
        },
        {
          name: '匿名反溯源链路设备',
          path: 'AnonymousLine'
        },
        {
          name: '网络空间指纹探测平台',
          path: 'Source'
        },
        {
          name: '检测工具',
          path: 'Tool'
        },
        {
          name: '远程取证工具集',
          path: 'Verify'
        },
        {
          name: '定制化系统平台研发',
          path: 'Custom'
        }
      ]
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    Jump(item) {
      // 跳转到指定页面并滚动到顶部
      if (this.$route.name === item.path) {
        this.scrollToTop()
        return
      }
      this.$router.push({ name: item.path })
      this.scrollToTop()
    },
    handleScroll() {
      if (window.scrollY > window.innerHeight / 1) {
        this.showButton = true
      } else {
        this.showButton = false
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
<style>
.back-to-top {
  position: fixed;
  bottom: 200px;
  right: 50px;
  cursor: pointer;
  /* background-color: #38908d; */
  padding: 5px;
  border-radius: 8px;
  /* color: white; */
  border: 1px solid;
}
.footer-border {
  border-bottom: 1px solid #272828;
  padding: 10px;
}

.footer-logo-text {
  margin-top: 5px;
  font-size: 11px;
}

.footer-logo-desc {
  font-size: 9px;
  color: #686a6a;
}

.footer-mian-prd-title {
  margin-bottom: 10px;
}

.footer-mian-prd-content {
  color: #999999;
  padding-top: 5px;
  cursor: pointer;
}

.footer-mian-prd {
  text-align: left;
}

.footer-main {
  color: white;
  height: 243px;
  margin-top: 134px;
  background-color: black;
  padding-top: 33px;
}
.footer-logo {
  background-image: url('../../assets/1x/footer-logo.png');
  background-size: 100% 100%;
  height: 45px;
  width: 37px;
  margin-top: 35px;
  margin: 0 auto;
}

/* 电脑端 */
@media (min-width: 768px) {
}

/* 手机端 */
@media (max-width: 768px) {
  .el-row--flex {
    display: flow-root !important;
  }
  .footer-main {
    height: 100%;
  }
  .footer-mian-prd {
    text-align: center;
  }
}
</style>
