<template>
  <div id="app">
    <router-view />
    <Footer v-if="$route.meta.showFooter" />
  </div>
</template>
<script>
import Footer from './layout/components/Footer.vue'

export default {
  components: {
    Footer
  }
}
</script>
<style>
body {
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  pading: 0;
  margin: 0;
  height: auto;
  width: 100%;
  background-image: url('./assets/1x/background.png');
  background-repeat: repeat;
}

/* 定义滚动条的样式 */
::-webkit-scrollbar {
  width: 5px; /* 设置滚动条的宽度 */
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: #000000; /* 设置滚动条的背景颜色 */
}

::-webkit-scrollbar-thumb {
  background-color: #205353; /* 设置滑块的颜色 */
  border-radius: 5px; /* 设置滑块的圆角半径 */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #66ffff; /* 设置鼠标悬停时滑块的颜色 */
  opacity: 0.5;
}
</style>
